import React from 'react'
import PageOverview from './Pages/Pageoverview'
import PageDetail from './Pages/PageDetail'
import PageNew from './Pages/new'
import CalenderDeleted from './Pages/deleted'

export const CalenderPagesConfig = (value: any) => {
    return [
        {
            path: '/deadman/overview/:page',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            elements: <PageOverview value={value} />
        },
        {
            path: '/deadman/Detail/:id',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            elements: <PageDetail value={value} />
        },
        {
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            path: '/deadman/Delete/:page/:deleteID',
            elements: <CalenderDeleted value={value} />
        },
        {
            path: '/deadman/new/:page',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            elements: <PageNew value={value} />
        }
    ]
}
export const CalenderMenuConfig = (value: any) => {
    return [
        {
            header: 'Verwaltung',
            icon: 'manage_accounts',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            menuitem: [
                {
                    name: 'Dashboard',
                    route: '/',
                    icon: 'dashboard',
                    roles: ['superadmin','admin','consultant','engineer']
                },
                /*{
                    name: 'Adressen',
                    route: '/customer/overview/1',
                    icon: 'place',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator'])
                },*/
                {
                    name: 'Kunde',
                    route: '/customer/overview/1',
                    icon: 'support_agent',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin','consultant','engineer'])
                },
                /*{
                    name: 'Kalender',
                    route: '/kalender/overview/1',
                    icon: 'calendar_month',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin','consultant','engineer'])
                },*/
                {
                    name: 'Serviceauftrag',
                    route: '/serviceauftrag/overview/1',
                    icon: 'engineering',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin','consultant','engineer'])
                },
                {
                    name: 'SA-Grundfos',
                    route: '/serviceauftragGrundfos/overview/1',
                    icon: 'water_drop',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin','consultant','engineer'])
                },
                /*{
                    name: 'VDE-Prüfung',
                    route: '/vde/overview/1',
                    icon: 'electrical_services',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin','consultant','engineer'])
                },*/{
                    name: 'Userverwaltung',
                    route: '/Member/overview/1',
                    icon: 'manage_accounts',
                    roles: ['superadmin', 'admin','consultant']
                }
            ]
        },
        /*{
            header: 'Einstellungen',
            icon: 'manage_accounts',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'consultant','engineer']),
            menuitem: [
                {
                    name: 'Anwendung',
                    route: '/',
                    icon: 'apps',
                    roles: ['superadmin','admin','consultant','engineer']
                },
                {
                    name: 'Aktion',
                    route: '/',
                    icon: 'build',
                    roles: ['superadmin','admin','consultant','engineer']
                },
                {
                    name: 'Hersteller',
                    route: '/',
                    icon: 'maps_home_work',
                    roles: ['superadmin','admin','consultant','engineer']
                },
            ]
        }*/
    ]
}
export const CalenderSidebar: string = '/Calender/overview/1'

export const dashboardservice = () => [
    /*{
        title: 'Adressen',
        href: '/customer/overview/1',
        undertitle: 'CMS',
        icon: 'place',
        roles: ['superadmin', 'admin', 'author', 'contentcreator', 'operator'],
        text: 'Adressen'
    },*/
    {
        title: 'Kunde',
        href: '/customer/overview/1',
        undertitle: 'CMS',
        icon: 'support_agent',
        roles: ['superadmin', 'admin','consultant','engineer'],
        text: 'Kunde'
    },
    /*{
        title: 'Kalender',
        href: '/kalender/overview/1',
        undertitle: 'CMS',
        icon: 'calendar_month',
        roles: ['superadmin', 'admin','consultant','engineer'],
        text: 'Kalender'
    },*/
    {
        title: 'Serviceauftrag',
        href: '/serviceauftrag/overview/1',
        undertitle: 'CMS',
        icon: 'engineering',
        roles: ['superadmin', 'admin','consultant','engineer'],
        text: 'Kunde'
    },
    {
        title: 'SA-Grundfos',
        href: '/serviceauftragGrundfos/overview/1',
        undertitle: 'CMS',
        icon: 'water_drop',
        roles: ['superadmin', 'admin','consultant','engineer'],
        text: 'Kunde'
    },
    /*{
        title: 'VDE-Prüfung',
        href: '/vde/overview/1',
        undertitle: 'CMS',
        icon: 'electrical_services',
        roles: ['superadmin', 'admin','consultant','engineer'],
        text: 'Kunde'
    },*/
    {
        title: 'Userverwaltung',
        href: '/Member/overview/1',
        undertitle: 'CMS',
        icon: 'manage_accounts',
        roles: ['superadmin', 'admin','consultant'],
        text: 'Kunde'
    }
]
